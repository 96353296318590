.swiper .swiper-button-disabled {
    cursor: not-allowed;
    opacity: 0.6;
}

.rtl .swiper {
    direction: rtl;
}

.swiper-button-next {
    color: #4361ee;
}

#slider3 .swiper-wrapper,
#slider4 .swiper-wrapper {
    height: 320px !important;
}

#slider3 .swiper-wrapper .swiper-slide img,
#slider4 .swiper-wrapper .swiper-slide img {
    height: 100%;
    object-fit: cover;
}

#slider3 .swiper-pagination .swiper-pagination-bullet {
    border-radius: 6px;
    width: 4px;
    height: 20px;
}

@media only screen and (max-width: 1200px) {
    #slider3 .swiper-wrapper,
    #slider4 .swiper-wrapper {
        height: 300px !important;
    }
}

@media only screen and (max-width: 600px) {
    #slider3 .swiper-wrapper,
    #slider4 .swiper-wrapper {
        height: 180px !important;
    }
}

#slider3 .swiper-pagination .swiper-pagination-bullet:hover {
    background-color: #fff;
}

#slider3 .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #4361ee;
}

#slider4 .swiper-pagination {
    color: #fff;
}
#slider5 .swiper-wrapper {
    height: auto;
}
#slider5 .swiper-pagination {
    position: relative;
    margin-top: 20px;
}
