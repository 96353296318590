.zoom {
  transition: transform 0.2s;
}

.zoom:hover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  left: 0;
  top: 100%;
  min-width: 150px;
  max-width: 400px;
  padding: 10px;
  background-color: #333;
  color: #fff;
  white-space: pre-wrap;
  word-break: break-all;
  z-index: 100;
}

.text-container {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
}
